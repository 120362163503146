<template>
  <div class="col-10 col-md-7 col-xl-6 mb-3">
    <div class="row">
      <div class="col-12 text-center mb-4 mt-1">
        <Button color="green" btnText="Add New Bank Account" icon="arrow" @buttonClicked="useNewBank">
          <IconPlus class="inline-icon" />
        </Button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CardPlain v-for="(option, key) in availableDebitOrders" :key="key" padding="py-2 px-3" class="mb-3" :isSmall="true">
          <div class="row" @click="selectBank(option.donorBankAccount)">
            <div class="col-12" >
              <div class="row align-items-center">
                <div class="col-auto">
                  <img :src="require('@/assets/images/' + imageURL(option.donorBankAccount.bankName) + '.png')" class="item_img" v-if="imageURL(option.donorBankAccount.bankName) === 'absa' || imageURL(option.donorBankAccount.bankName) === 'fnb' || imageURL(option.donorBankAccount.bankName) === 'al-rayaanbank' || imageURL(option.donorBankAccount.bankName) === 'nedbank'">
                  <IconBank color="gold" size="size28" v-else />
                </div>
                <div class="col p-0">
                  <div class="row mx-0">
                    <div class="col-12 text-uppercase font12">
                      {{option.donorBankAccount.accountHolderName}} - {{option.donorBankAccount.accountNumber}}
                    </div>
                    <div class="col-12 bold">
                      {{option.donorBankAccount.bankName}}
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <Button color="gold" size="small" width="100" btnText="Selected" v-if="sadaqahWizard.pageDetails_2.account.number === option.donorBankAccount.accountNumber" />
                  <Button color="green" size="small" width="100" btnText="Select" v-else />
                </div>
              </div>
            </div>
          </div>
        </CardPlain>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue'))
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'useNewBank'],
  computed: {
    ...mapGetters([
      'debitOrders'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    imageURL (val) {
      const lowercase = val.toLowerCase()
      return lowercase
    },
    availableDebitOrders () {
      const arr = []
      return this.debitOrders.filter(element => {
        if (arr.includes(element.donorBankAccount.accountNumber)) {
          return false
        } else {
          arr.push(element.donorBankAccount.accountNumber)
          return true
        }
      })
    }
  },
  methods: {
    selectBank (val) {
      this.value.bankName = val.bankName
      this.value.accountNumber = val.accountNumber
      this.value.accountHolderName = val.accountHolderName
      this.value.branchCode = val.bankBranch.code
    },
    useNewBank () {
      this.$emit('useNewBank')
    }
  }
}
</script>
<style scoped>
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
